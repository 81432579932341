export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [];

export const dictionary = {
		"/": [7],
		"/blog": [20],
		"/blog/[slug]": [21],
		"/contact": [22,[3]],
		"/(redirects)/demo": [~8],
		"/(redirects)/devanddesign": [~9],
		"/(redirects)/discord": [~10],
		"/(redirects)/docs": [11,[2]],
		"/(redirects)/github": [~12],
		"/(redirects)/learn": [~13],
		"/(redirects)/new": [~14],
		"/oss-friends": [23],
		"/(redirects)/ph": [~15],
		"/pricing": [24,[4]],
		"/(redirects)/privacy": [~16],
		"/roadmap": [25],
		"/(redirects)/signup": [~17],
		"/teach": [26,[5]],
		"/teach/register": [~27,[5]],
		"/tools": [28,[6]],
		"/tools/name-picker": [29,[6]],
		"/tools/pomodoro": [30,[6]],
		"/tools/progress": [31,[6]],
		"/tools/stopwatch": [32,[6]],
		"/tools/tic-tac-toe": [33,[6]],
		"/(redirects)/tos": [~18],
		"/(redirects)/waec": [~19]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';